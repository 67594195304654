@font-face {
    font-family: BalooThambi2;
    src: url('../../Font/BalooThambi2-VariableFont_wght.ttf');
}

.TelaBI {
    width: 100%;
    background-image: url('../../img/Background/Postion\ Absolute\ -\ bg-01-1.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-01-2.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-01-3.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-01.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-04\ blue.png');
    background-repeat: no-repeat;
    background-position-y: -3rem, -3rem, -3rem, -3rem, 115%;
    background-position-x: 0, 14%, 27.5%, 41%, 105%;
    background-size: 59%, 59%, 59%, 59%, 35%;
}

.contBI {
    padding-top: 7rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-image: url('../../img/Background/Postion\ Absolute\ -\ bg-01-3.png');
    background-position-y: 7rem;
    background-size: 80%;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
}

.inicioBI {
    width: 59%;
    margin-top: 1rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-image: linear-gradient(100deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.5) 100%);
}
.inicioBI>div {
    width: 100%;
    /* height: 20rem; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.novBI {
    width: 62.5%;
    min-height: 40rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-top: 1rem;
    background-color: rgba(255, 255, 255, 0.5);
}
.novBI>div:last-child {
    width: 70%;
}
.novBI>div>h1 {
    color: #666666;
    font-family: BalooThambi2;
    letter-spacing: 2px;
}

@media screen and (max-width: 1366px) and (max-height: 768px) {
    .TelaBI {
        background-position-x: 0, 14%, 27.5%, 49.5%, 105%;
    }

    .contBI {
        background-position-y: 6rem;
    }

    .inicioBI {
        width: 75.5%;
    }

    .novBI {
        width: 80.5%;
        min-height: 20rem;
    }
}

@media screen and (max-width: 1080px) and (max-height: 2400px) {
    .TelaBI {
        background-image: none;
        background-color: #9EACF0;
    }

    .contBI {
        background-image: none;
    }

    .inicioBI {
        width: 92%;
        padding-left: 0;
        padding-right: 0;
    }
    .inicioBI>div {
        width: 100% !important;
        margin: 0 1rem;
    }

    .novBI {
        width: 93%;
        /* min-height: 20rem; */
    }
    .novBI>div>h1 {
        font-size: large;
    }
    .novBI>div:last-child {
        width: 90%;
    }

}