@font-face {
    font-family: BalooThambi2;
    src: url('../../Font/BalooThambi2-VariableFont_wght.ttf');
}

.TelaRP {
    width: 100%;
    background-image: url('../../img/Background/Postion\ Absolute\ -\ bg-01-1.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-01-2.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-01-3.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-01.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-04-1.png');
    background-repeat: no-repeat;
    background-position-y: -3rem, -3rem, -3rem, -3rem, 115%;
    background-position-x: 0, 14%, 27.5%, 41%, 107%;
    background-size: 59%, 59%, 59%, 59%, 35%;
}

.contRP {
    padding-top: 7rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-image: url('../../img/Background/Postion\ Absolute\ -\ bg-01.png');
    background-position-y: 7rem;
    background-size: 80%;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
}

.inicioRP {
    width: 60%;
    margin-top: 1rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1rem 2rem 1rem;
    background-image: linear-gradient(100deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.5) 100%);
}
.inicioRP>div:first-child>h1 {
    font-family: BalooThambi2;
    letter-spacing: 2px;
}
.inicioRP>div:last-child {
    display: flex;
}
.inicioRP>div:last-child>div {
    display: flex;
    align-items: center;
    justify-content: center;
}
.inicioRP>div:last-child>div:last-child>img {
    width: 85%;
}
.inicioRP>div:last-child>div>form {
    border: 2px solid #E68B8B;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}
.inicioRP>div:last-child>div>form>div{
    display: flex;
}

.inicioRP>div:last-child>div>form>div>div:first-child {
    display: flex;
    width: 65%;
    flex-direction: column;
    margin: 1rem 1rem 1rem 2rem;
}
.inicioRP>div:last-child>div>form>div>div:first-child>input {
    margin-bottom: 1rem;
    height: 2rem;
}
.inicioRP>div:last-child>div>form>div>div:first-child>input, .inicioRP>div:last-child>div>form>div>div:first-child>textarea {
    background-color: #666666;
    border: none;
    border-radius: 1rem;
    color: white;
    padding-left: 1rem;
}
.inicioRP>div:last-child>div>form>div>div:first-child>textarea {
    padding-top: 0.25rem;
}
.inicioRP>div:last-child>div>form>div>div:first-child>input::placeholder, .inicioRP>div:last-child>div>form>div>div:first-child>textarea::placeholder {
    color: white;
    font-weight: bold;
}

.inicioRP>div:last-child>div>form>div>div:last-child {
    display: flex;
    width: 30%;
    flex-direction: column;
    margin: 1rem 2rem 1rem 1rem;
}
.inicioRP>div:last-child>div>form>div>div:last-child>div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    border-radius: 1rem;
    background-color: #666666;
    margin-top: 1rem;
}
.inicioRP>div:last-child>div>form>div>div:last-child>div>img {
    width: 100%;
}

.inicioRP>div:last-child>div>form>div:last-child{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.inicioRP>div:last-child>div>form>div:last-child>button {
    background-color: #CD1616;
    width: 25%;
    height: 2rem;
    border: none;
    border-radius: 1rem;
    color: white;
    font-weight: bold;
}


.novRP {
    width: 61.5%;
    min-height: 40rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-top: 1rem;
    margin-top: 1rem;
    background-color: rgba(255, 255, 255, 0.5);
}
.novRP>div:last-child {
    width: 70%;
}
.novRP>div>h1 {
    color: #666666;
    font-family: BalooThambi2;
    letter-spacing: 2px;
}


@media screen and (max-width: 1366px) and (max-height: 768px) {

    .TelaRP {
        background-position-x: 0, 14%, 27.5%, 49.5%, 110%;
    }

    .contRP {
        background-position-y: 6rem;
    }

    .inicioRP {
        width: 77.5%;
    }

    .novRP {
        width: 80%;
        min-height: 20rem
    }
}

@media screen and (max-width: 1080px) and (max-height: 2400px) {

    .TelaRP {
        background-image: none;
        background-color: #E68B8B;
    }
    .contRP {
        background-image: none;
    }

    .inicioRP {
        width: 84.5%;
    }
    .inicioRP>div:last-child, .inicioRP>div:last-child>div:first-child>form>div:first-child {
        flex-direction: column;
    }
    .inicioRP>div:last-child>div:first-child>form>div:first-child {
        align-items: center;
        justify-content: center;
    }
    .inicioRP>div:last-child>div:first-child>form>div:first-child>div {
        width: 100%;
        margin: 1rem 0 1rem 0;
    }

    .novRP {
        width: 93%;
        min-height: 20rem;
    }
    .novRP>div>h1 {
        font-size: large;
    }
    .novRP>div:last-child {
        width: 90%;
    }

}