@font-face {
    font-family: BalooThambi2;
    src: url('../../Font/BalooThambi2-VariableFont_wght.ttf');
}

.tabelaDemandas {
    width: 100%;
    max-height: 900px;
    overflow-y: scroll;
    overflow-x: auto;
}

table {
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: white;
    border-spacing: 0 1rem;
    width: 100%;
    text-align: center;
    border-radius: 1rem;
    font-family: BalooThambi2;
}
table,tr,th,td {
    border-collapse: separate;
    border: none;
}
table>tr>td:first-child, table>tr>th:first-child {
    border-radius: 1rem 0 0 1rem;
}
table>tr>td:last-child, table>tr>th:last-child {
    border-radius: 0 1rem 1rem 0;
}
table>tr:first-child {
    background-color: #828282;
    height: 3rem;
    color: white;

}
table>tr>td>button {
    background-color: transparent;
    border: none;
}
table>tr>td>button:hover {
    cursor: pointer;
}

.atendido {
    background-color: #8ED98E;
}
.em_atendimento {
    background-color: #F7E59B;
}
.nao_avaliado {
    background-color: #9EACF0;
}
.recusado {
    background-color: #E68B8B;
}