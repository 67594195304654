@font-face {
    font-family: BalooThambi2;
    src: url('../../Font/BalooThambi2-VariableFont_wght.ttf');
}

.telaPerfil {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-image: url('../../img/Background/Postion\ Absolute\ -\ bg-01-1.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-01-2.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-01-3.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-01.png')
    , url('../../img/Background/Postion Absolute - bg-04 blue.png');
    background-repeat: no-repeat;
    background-position-y: -3rem, -3rem, -3rem, -3rem, 160%;
    background-position-x: 0, 14%, 27.5%, 41%, 50%;
    background-size: 59%, 59%, 59%, 59%, 70% 70%;
}

.contentPerfil {
    padding-top: 7rem;
    display: flex;
    width: 100%;
    background-image: url('../../img/Background/Postion Absolute - bg-01-1.png');
    background-repeat: no-repeat;
    background-position-y: 7rem;
    background-size: 80%;
    flex-direction: column;
    align-items: center;
}

.geral {
    display: flex;
    width: 60%;
    border-radius: 1rem;
    font-family: BalooThambi2;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 0.5) 100%);
}
.geral>div {
    text-align: center;
}
.geral>div:first-child {
    margin-left: 5rem;
    padding-top: 1rem;
    border-radius: 1rem;
    width: 25%;
    background-color: #E2E2E2;
}
.geral>div:first-child>div>img {
    width: 50%;
    border-radius: 100%;
}
.geral>div:first-child>div>button {
    position: absolute;
    padding: 5px 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 1rem;
    cursor: pointer;
}
.geral>div:first-child>h1 {
    margin-bottom: 0;
}
.geral>div:last-child {
    display: flex;
    justify-content: space-between;
    margin-right: 10rem;
    width: 45%;
}
.geral>div:last-child>div:first-child>* {
    margin: 0;
}
.geral>div:last-child>div:first-child>div:first-child {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 2px solid #828282;
    border-radius: 1rem;
    color: black;
}
.geral>div:last-child>div:first-child>div:first-child>h1>img {
    width: 12%;
    margin-right: 0.5rem;
}
.geral>div:last-child>div:first-child>div:first-child>h1, .geral>div:last-child>div:first-child>div:first-child>p {
    margin: 0;
}
.geral>div:last-child>div:first-child>div:last-child {
    width: 108%;
}
.geral>div:last-child>div:first-child>div:last-child>button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 3rem;
    border: none;
    border-radius: 1rem;
    background-color: #828282;
    color: white;
    font-size: large;
    font-family: BalooThambi2;
}
.gerenDemandas {
    background-color: #1DB31D !important;
    border: 2px solid #828282 !important;
}
.geral>div:last-child>div:first-child>div:last-child>button:hover {
    cursor: pointer;
}
.geral>div:last-child>div:first-child>div:last-child>button>img {
    width: 10%;
    margin-right: 0.5rem;
}
.geral>div:last-child>div:last-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.geral>div:last-child>div:last-child>div>button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: 100%;
    height: 3rem;
    margin-bottom: 1rem;
    background-color: #828282;
    border-radius: 1rem;
    color: white;
    font-size: large;
    font-family: BalooThambi2;
}
.geral>div:last-child>div:last-child>div>button>img {
    width: 10%;
    margin-right: 0.5rem;
}

.geral>div:last-child>div:last-child>div:last-child {
    margin-top: 2.5rem;
    margin-bottom: 0;
    width: 100%;
    display: flex;
    justify-content: end;
}
.geral>div:last-child>div:last-child>div:last-child>button {
    width: 40%;
    background-color: #E68B8B;
    height: 2rem;
}
.geral>div:last-child>div:last-child>div>button:hover {
    cursor: pointer;
}


.hist, .eventosPerfil {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    padding-bottom: 2rem;
    border-radius: 1rem;
    width: 60%;
    background-color: rgba(255, 255, 255, 0.5);
}
.hist>div:last-child, .eventosPerfil>div:last-child {
    width: 90%;
    max-height: 900px;
    overflow-y: scroll;
    overflow-x: auto;
}

.eventosPerfil>div:first-child {
    display: flex;
    width: 90%;
    justify-content: end;
    align-items: center;
}
.eventosPerfil>div:first-child>h1{
    margin-right: 15%;
}
.eventosPerfil>div:first-child>div{
    width: 15%;
    margin-right: 15%;
}
.eventosPerfil>div:first-child>div>button {
    width: 100%;
    background-color: #828282;
    color: white;
    font-size: large;
    font-family: BalooThambi2;
    border: none;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
}
.eventosPerfil>div:first-child>div>button:hover {
    cursor: pointer;
}
.eventosPerfil>div:first-child>div>button>img {
    width: 15%;
    margin-right: 0.5rem;
}

/* celular */

@media screen and (max-width: 1080px) and (max-height: 2400px) {
    .telaPerfil {
        background-image: none;
        background-color: #8ED98E !important;
    }

    .contentPerfil {
        padding-top: 3.5rem !important;
        background-image: none;
    }

    .geral {
        width: 95% !important;
        flex-direction: column;
        justify-content: center;
        /* background-image: none; */
        align-items: center;
    }
    .geral>div {
        width: 60% !important;
    }
    .geral>div:first-child {
        margin: 0 0 1rem 0;
    }
    .geral>div:last-child {
        margin: 0;
        flex-direction: column;
    }
    .geral>div:last-child>div:first-child {
        margin-bottom: 1rem;
    }

    .atv>fieldset {
        width: 70% !important;
    }
    .atv>fieldset>div {
        width: 100%;
        height: 25%;
    }

    .hist, .eventosPerfil {
        width: 95% !important;
    }

    .eventosPerfil>div:first-child {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
    }
    .eventosPerfil>div:first-child>h1, .eventosPerfil>div:first-child>div{
        margin: 1rem 0 1rem 0;
    }
    .eventosPerfil>div:first-child>div {
        width: 50%;
    }
}

@media (min-width: 1920px) and (max-width: 2560px) and (min-height: 1080px) and (max-height: 1440px) {
    .telaPerfil {
        background-position-y: 7rem, 140%;
        background-size: 70%, 60% 60%;
    }
    .atv>fieldset {
        width: 20%;
    }
}

@media screen and (max-width: 1366px) and (max-height: 768px) {
    .telaPerfil {
        background-position-y: -3rem, -3rem, -3rem, -2.5rem, 160%;
        background-position-x: 0, 14%, 27.5%, 49%, 50%;
        background-size: 59%, 59%, 59%, 59%, 70% 70%;
    }
    .contentPerfil {
        background-position-y: 6rem;
        padding-top: 6rem;
    }
    .geral {
        width: 70%;
    }
    .geral>div:last-child {
        width: 60%;
        margin-right: 0;
    }
    .hist, .eventosPerfil {
        width: 70%;
    }
    
    .atv>fieldset {
        width: 30%;
    }
}