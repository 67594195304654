@font-face {
    font-family: BalooThambi2;
    src: url('../../Font/BalooThambi2-VariableFont_wght.ttf');
}

.telaEditPerfil {
    border: 2px solid #CED1D5;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-radius: 1rem;
    background-image: none;
    background-color: white;
}

#inputSenha1, #inputSenha3 {
    display: none;
}

.group2>div:last-child {
    width: 100%;
    display: flex;
    justify-content: center;
}
.group2>div>button {
    width: 10%;
    height: 2rem;
    border-radius: 1rem;
    font-size: larger;
    font-family: BalooThambi2;
    color: white;
    background-color: #8ED98E;
    border: none;
    margin-top: 1rem;
}
.group2>div>button:hover {
    cursor: pointer;
}

@media screen and (max-width: 1080px) and (max-height: 2400px) {
    .group2>div>button {
        width: 15%;
    }
    .group2>div>button {
        width: 25%;
    }
}