@font-face {
    font-family: BalooThambi2;
    src: url('../../Font/BalooThambi2-VariableFont_wght.ttf');
}

.titModal {
    width: 100%;
    text-align: center;
    font-family: BalooThambi2;
    letter-spacing: 1px;
}

.modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 10px;
    width: 60%;
    max-width: 90%;
    background-color: white;
    overflow: auto;
    max-height: 90vh;
}

.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

@media screen and (max-width: 1080px) and (max-height: 2400px) {
    .modal-content {
        width: 80%;
    }
}