.telaMsgEventoCriado {
    width: 100%;
}
.telaMsgEventoCriado>div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.telaMsgEventoCriado>div>p {
    font-weight: bolder;
    color: #116d11;
}
.telaMsgEventoCriado>div>button {
    width: 30%;
    height: 2rem;
    border-radius: 1rem;
    border: none;
    color: white;
    font-size: large;
    font-weight: bolder;
    font-family: BalooThambi2;
    background-color: #1DB31D;
    transition: background-color 0.5s ease;
}
.telaMsgEventoCriado>div>button {
    cursor: pointer;
    background-color: #116d11;
}