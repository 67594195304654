.telaAddEvento>form>div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}
.telaAddEvento>form>div:first-child>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 0 1rem 0 1rem;
}
.telaAddEvento>form>div:first-child>div:first-child>select {
    width: 83%;
}
.telaAddEvento>form>div:first-child>div:first-child>input {
    width: 80%;
    height: 2rem;
    border-radius: 1rem;
    border: none;
    background-color: #666666;
    box-shadow: 5px 2px 2px lightgray;
    padding-left: 1rem;
    color: white;
    font-weight: bolder;
    font-family: BalooThambi2;
    margin-bottom: 1rem;
}
.telaAddEvento>form>div:first-child>div:first-child>textarea {
    width: 80%;
    min-height: 8rem;
    background-color: #666666;
    border: none;
    border-radius: 1rem;
    color: white;
    padding-left: 1rem;
}
.telaAddEvento>form>div:first-child>div:first-child>input::placeholder, .telaAddEvento>form>div:first-child>div:first-child>textarea::placeholder {
    color: white;
    font-weight: bold;
}
.telaAddEvento>form>div:first-child>div:last-child {
    justify-content: center;
    align-items: center;
}
.telaAddEvento>form>div:first-child>div:last-child>div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 15rem;
    background-color: #666666;
    border-radius: 1rem;
    margin-top: 1rem;
}
.telaAddEvento>form>div:first-child>div:last-child>div>img {
    width: 80%;
}

.telaAddEvento>form>div:last-child {
    margin-top: 1rem;
}
.telaAddEvento>form>div:last-child>button {
    width: 10%;
    height: 2rem;
    border-radius: 1rem;
    border: none;
    color: white;
    font-size: large;
    font-weight: bolder;
    font-family: BalooThambi2;
    background-color: #1DB31D;
    transition: background-color 0.5s ease;
}.telaAddEvento>form>div:last-child>button:hover {
    cursor: pointer;
    background-color: #116d11;
}

@media screen and (max-width: 1080px) and (max-height: 2400px) {
    .telaAddEvento>form>div{
        flex-direction: column;
    }
    .telaAddEvento>form>div:first-child>div:first-child {
        margin-bottom: 1rem;
    }

    .telaAddEvento>form>div:last-child {
        align-items: center;
        width: 100%;
    }
    .telaAddEvento>form>div:last-child>button {
        width: 30%;
    }
}