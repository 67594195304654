.telaMsgDemandaCraida {
    width: 100%;
    display: flex;
    justify-content: center;
}
.telaMsgDemandaCraida>div>p {
    text-align: center;
    font-weight: bold;
    font-size: large;
    color: #666666;
}
.telaMsgDemandaCraida>div>p:first-child {
    font-weight: bolder;
    color: #116d11;
}