@font-face {
    font-family: BalooThambi2;
    src: url('../../Font/BalooThambi2-VariableFont_wght.ttf');
}

.titModalP {
    width: 100%;
    text-align: center;
    font-family: BalooThambi2;
    font-size: x-large;
    letter-spacing: 1px;
}

.modal-overlayP {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.false {
    backdrop-filter: blur(10px);
}

.modal-contentP {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 10px;
    width: 20%;
    max-width: 90%;
    background-color: white;
    overflow: auto;
    max-height: 90vh;
}

.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}
.modal-close-button-false {
    display: none;
}

@media screen and (max-width: 1080px) and (max-height: 2400px) {
    .modal-contentP {
        width: 70% !important;
    }
}