@font-face {
    font-family: BalooThambi2;
    src: url('../../Font/BalooThambi2-VariableFont_wght.ttf');
}

.TelaEntrar {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-image: url('../../img/Background/Postion\ Absolute\ -\ bg-01-1.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-01-2.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-01-3.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-01.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-04\ black.png');
    background-repeat: no-repeat;
    background-position-y: -3rem, -3rem, -3rem, -3rem, 100%;
    background-position-x: 0, 14%, 27.5%, 41%, 100%;
    /* background-position: ; */
    background-size: 59%, 59%, 59%, 59%, 50%;
}

.contentLogin {
    padding-top: 7rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-image: url('../../img/Background/Postion\ Absolute\ -\ bg-01\ entrar.png');
    background-position-y: 7rem;
    background-repeat: no-repeat;
    background-size: 80%;
    justify-content: center;
    align-items: center;
}
.contentLogin>div {
    display: flex;
    width: 60%;
    justify-content: center;
    align-items: center;
    flex: 1;
    border-radius: 1rem;
}
.contentLogin>div:first-child {
    margin-top: 2rem;
    /* background-image: linear-gradient(90deg, rgba(255, 255, 255, 1) 47%, rgba(255, 255, 255, 0.5) 100%); */
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}

.contentLogin>div>div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.login, .cadastroLogin {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-right: 5rem;
    margin-left: 5rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
    border-radius: 1rem;
    border: 2px solid #CED1D5;
    background-color: white;
}
.login>div {
    display: flex;
    justify-content: right;
    width: 75%;
}
.login>div>input {
    width: 67%;
    height: 2rem;
    border-radius: 1rem;
    border: none;
    background-color: #666666;
    box-shadow: 5px 2px 2px lightgray;
    padding-left: 1rem;
    color: white;
    font-weight: bolder;
    font-family: BalooThambi2;
}
.login>div>button {
    margin-left: 0.9rem;
    background-color: transparent;
    font-size: large;
    border: none;
}
.login>div>button:hover {
    cursor: pointer;
}

.contentLogin>div>div>form>h1{
    font-family: BalooThambi2;
    font-size: xx-large;
    font-weight: normal;
}
.contentLogin>div>div>form>input {
    width: 50%;
    height: 2rem;
    border-radius: 1rem;
    border: none;
    background-color: #666666;
    box-shadow: 5px 2px 2px lightgray;
    padding-left: 1rem;
    color: white;
    font-weight: bolder;
    font-family: BalooThambi2;
}
.contentLogin>div>div>form>input::placeholder {
    color: white;
    font-family: BalooThambi2;
}
.contentLogin>div>div:first-child>form>input {
    margin-bottom: 1.5rem;
}
.contentLogin>div>div:last-child>form>input {
    margin-bottom: 0.5rem;
}

#acessoNegado {
    width: 100%;
    display: none;
    color: red;
    font-weight: bold;
}
#acessoNegado>p {
    width: 100%;
    text-align: center;
}

.buttonEntLogin, .buttonCadLogin {
    width: 40%;
    height: 2rem;
    border-radius: 1rem;
    border: none;
    font-weight: lighter;
    color: white;
    font-family: BalooThambi2;
    font-size: large;
    /* margin-bottom: 1rem; */
}
.buttonEntLogin {
    margin-top: 5rem;
    background-color: #116d11;
}
.buttonCadLogin {
    margin-top: 1rem;
    background-color: #1DB31D;
}

.esqSenha {
    margin-top: 1rem;
    background-color: transparent;
    color: #666666;
    font-weight: lighter;
}
.esqSenha:hover {
    color: #116d11;
    text-decoration: underline;
}
/* .contentLogin>div>div:first-child>form>button {
    margin-top: 9rem;
} */

.contentLogin>div:last-child>img {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

@media screen and (max-width: 1080px) and (max-height: 2400px) {
    .TelaEntrar {
        background-image: none;
        background-color: #CED1D5;
    }

    .contentLogin {
        background-image: none;
    }

    .contentLogin>div {
        width: 95% !important;
        flex-direction: column;
    }

    .contentLogin>div>div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0;
    }

    .login, .cadastroLogin {
        width: 90%;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .contentLogin>div>div>form>input {
        width: 70%;
    }
    .login>div {
        width: 100%;
    }
    .login>div>input {
        width: 70%;
    }
    .contentLogin>div:last-child>img {
        width: 80%;
    }
}

 @media screen and (max-width: 1366px) and (max-height: 768px) {
    .TelaEntrar{
        background-position-y: -3rem, -3rem, -3rem, -3rem, 100%;
        background-position-x: 0, 14%, 27.5%, 49.5%, 100%;
    }
    .contentLogin {
        background-position-y: 6rem;
        padding-top: 6rem;
    }
    .contentLogin>div {
        width: 80%;
    }
}