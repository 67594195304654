.calendar-geral {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.calendar-container {
  width: 60%;
  display: flex;
  flex-direction: row;
  background-color: white;
  border: 1px solid #3b82f6; /* Cor da borda azul */
  border-radius: 10px;
  overflow: hidden;
}

.calendar-header {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  background-color: #3b82f6;
  color: white;
  text-align: center;
  padding: 10px 0;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.week {
  display: flex;
  width: 100%;
}

.day {
  width: calc(100% / 7);
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #3b82f6;
  box-sizing: border-box;
  position: relative;
}

.day.event {
  background-color: #e0f7fa;
}
.day.event:hover {
  cursor: pointer;
}

.day-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.day-number {
    margin-bottom: 5px;
    font-weight: bold;
}

.eventos-container {
    display: flex;
}

.evento-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #007bff;
  margin-right: 2px;
  position: relative;
}

.calendar-controls {
  margin-top: 1rem;
  width: 10%;
  display: flex;
  justify-content: space-between;
}

.calendar-controls>button {
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  border: none;
  font-weight: bolder;
  font-size: large;
  color: white;
  background-color: #007bff;
}
.calendar-controls>button:hover {
  cursor: pointer;
}

@media screen and (max-width: 1080px) and (max-height: 2400px) {
  .calendar-container {
      width: 100%;
  }
  .evento-circle {
      width: 8px;
      height: 8px;
  }
}