.telaUsrNaoVerificado {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
}

.telaUsrNaoVerificado p {
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
    color: #666666;
    font-family: BalooThambi2;
}

.telaUsrNaoVerificado p:last-child {
    color: red;
}

.code-inputs {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.codVerifyInput {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 24px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
}

.codVerifyInput:focus {
    border-color: #1DB31D;
    box-shadow: 0 0 5px #116d11;
}

.confConta {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #1DB31D;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.5s ease;
}

.confConta:hover {
    background-color: #116d11;
}
