.telaEsqSenha {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.telaEsqSenha>div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}
.telaEsqSenha>div>p {
    text-align: center;
}
.telaEsqSenha>div>input{
    width: 50%;
    height: 2rem;
    border-radius: 1rem;
    border: none;
    background-color: #666666;
    box-shadow: 5px 2px 2px lightgray;
    padding-left: 1rem;
    color: white;
    font-weight: bolder;
    font-family: BalooThambi2;
}
.telaEsqSenha>div>input::placeholder {
    color: white;
    font-family: BalooThambi2;
}

.telaEsqSenha>div>button{
    width: 30%;
    height: 2rem;
    border-radius: 1rem;
    border: none;
    font-weight: lighter;
    color: white;
    font-family: BalooThambi2;
    font-size: large;
    background-color: #1DB31D;
    transition: background-color 0.5s ease;
}
.telaEsqSenha>div>button:hover {
    cursor: pointer;
    background-color: #116d11;
}

.telaEsqSenha div {
    text-align: center;
}