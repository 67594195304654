@font-face {
    font-family: BalooThambi2;
    src: url('../../Font/BalooThambi2-VariableFont_wght.ttf');
}
@font-face {
    font-family: Gitaluevo;
    src: url('../../Font/Gitaluevo.ttf');
}

.footer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.rodape {
    width: 60%;
    display: flex;
    flex-direction: column;
    padding-top: 4rem;
    text-align: center;
    padding: 1rem;
    border-radius: 1rem;
    background-image: linear-gradient( rgba(255, 255, 255, 0) 5%, rgba(255, 255, 255, 1) 60%);
}
.rodape>div {
    width: 100%;
    margin-top: 4rem;
    display: flex;
    justify-content: space-between;
}
.rodape>div>div>div>span>button, .rodape>div>div>div>span>a>button {
    border: none;
    background-color: transparent;
    text-align: left;
    width: 100%;
    padding: 0;
    font-family: BalooThambi2;
    font-size: 15px;
    font-weight: bold;
    color: #666666;
}
.rodape>div>div>div>span>button:hover, .rodape>div>div>div>span>a>button:hover {
    cursor: pointer;
}
.rodape>div>div {
    width: 30%;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: left;
}
.dirReserv {
    box-shadow: none !important;
}
.rodape>div>div>div:first-child {
    justify-content: left;
    align-items: center;
}
.rodape>div>div>div>img {
    width: 20%;
    height: 5%;
}
.rodape>div>div>p {
    margin-top: 0;
}
.rodape>div>div>div>h2 {
    margin-bottom: 0;
    font-family: Gitaluevo;
    font-weight: normal;
    font-size: xx-large;
}
.rodape>div>div>h2 {
    font-family: BalooThambi2;
    font-weight: normal;
}
.rodape>div>div>div {
    display: flex;
    justify-content: space-between;
}
.rodape>div>div>div>span {
    flex: 1;
}

.rodape>div:last-child>div {
    display: flex;
    flex: 1;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.rodape>div:last-child {
    align-items: center;
}
.rodape>div:last-child>div:first-child {
    justify-content: center;
}
.rodape>div>div>button {
    width: 9%;
    height: 3rem;
    border-radius: 100%;
    border: none;
    background-color: #666666;
    margin-right: 1rem;
}

@media screen and (max-width: 1080px) and (max-height: 2400px) {
    .rodape {
        width: 85% !important;
    }

    .rodape>div>div>h2 {
        font-size: 11px;
        font-weight: bold;
    }

    .rodape>div>div>div>img {
        height: 60%;
    }
    .rodape>div>div>div>h2 {
        width: 100%;
        font-size: small;
    }

    .rodape>div>div>button {
        width: 1.7rem !important;
        height: 1.7rem;
    }
    .rodape>div>div {
        width: 23%;
        padding-left: 0.5rem;
        font-size: xx-small;
    }
    .rodape>div>div>div>span>button {
        width: 100%;
        margin: 0;
        padding: 0;
        font-size: 8px;
        text-align: left;
        border-bottom: 1px solid;
    }

    .rodape>div:first-child>div, .rodape>div:first-child>div>div {
        display: flex;
        flex-direction: column;
    }

    .rodape>div:first-child>div>div:first-child {
        flex-direction: row;
    }
}

@media screen and (max-width: 1366px) and (max-height: 768px) {
    .rodape {
        width: 78%;
    }
    .rodape>div>div>button {
        width: 11%;
    }
}
