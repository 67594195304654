@font-face {
  font-family: BalooThambi2;
  src: url('../../Font/BalooThambi2-VariableFont_wght.ttf');
}
  
  .parceiros {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .parceiros>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
  }
  .parceiros>div:first-child {
    width: 100%;
    margin-top: 4rem;
  }
  .parceiros>div>button{
    width: 40%;
    height: 3rem;
    border-radius: 2rem;
    background-color: #3C59E1;
    border: none;
    box-shadow: 5px 5px 10px #cfcfcf;
    font-size: x-large;
    color: white;
    font-weight: bolder;
    font-family: BalooThambi2, sans-serif;
  }

  .sugestao {
    background-color: white;
    width: 75%;
    padding: 2rem;
    border-radius: 1rem;
  }
  .sugestao>form {
    width: 90%;
  }
  .sugestao>form>h1 {
    font-family: BalooThambi2;
    letter-spacing: 2px;
  }
  .sugestao>form>div{
    display: flex;
  }
  .sugestao>form>div>input {
    border: none;
    background-color: #666666;
    padding-left: 1rem;
    padding-right: 1rem;
    color: white;
    font-family: BalooThambi2;
    font-size: larger;
    border-radius: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  .sugestao>form>div>button {
    border: none;
    border-radius: 1rem;
    background-color: #8ED98E;
    font-weight: bolder;
    color: white;
    font-family: BalooThambi2;
    height: 2rem;
    width: 30%;
  }
  .sugestao>form>div>button:hover {
    cursor: pointer;
  }
  .sugestao>form>div>button:disabled {
    background-color: darkgray;
  }
  .sugestao>form>div>button:disabled:hover {
    cursor: default;
  }
  .sugestao>form>textarea {
    border: none;
    background-color: #666666;
    padding-left: 1rem;
    padding-right: 1rem;
    color: white;
    font-family: BalooThambi2;
    font-size: larger;
    border-radius: 1rem;
    width: 75%;
    height: 5rem;
  }
  .sugestao>form>textarea::placeholder {
    color: white;
  }
  
  .sugestao>p {
    text-align: center;
    font-family: BalooThambi2;
  }

  @media screen and (max-width: 1080px) and (max-height: 2400px) {
    .parceiros {
      width: 95% !important;
    }
  
    .parceiros>div>button {
      width: 90%;
    }

    .sugestao>form>div {
      width: 85%;
      flex-direction: column;
    }

    .sugestao>form>div>input {
      width: 80%;
    }

    .sugestao>form>textarea {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 1366px) and (max-height: 768px) {
    .sugestao>form>div>input {
      width: 90%;
    }

    .sugestao>form>textarea {
      width: 80%;
    }
  }