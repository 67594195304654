@font-face {
    font-family: BalooThambi2;
    src: url('../../Font/BalooThambi2-VariableFont_wght.ttf');
}

.TelaCadastro {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-image: url('../../img/Background/Postion\ Absolute\ -\ bg-01-1.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-01-2.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-01-3.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-01.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-04\ black.png');
    background-repeat: no-repeat;
    background-position-y: -3rem, -3rem, -3rem, -3rem, 100%;
    background-position-x: 0, 14%, 27.5%, 41%, 100%;
    background-size: 59%, 59%, 59%, 59%, 50%;
}

.contentCadastro {
    padding-top: 7rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-image: url('../../img/Background/Postion\ Absolute\ -\ bg-01\ cad.png');
    background-repeat: no-repeat;
    background-position-y: 7rem;
    background-size: 80%;
    justify-content: center;
    align-items: center;
}
.contentCadastro>div {
    display: flex;
    width: 60%;
    justify-content: center;
    flex: 1;
    border-radius: 1rem;
}
.contentCadastro>div:first-child {
    margin-top: 2rem;
    /* background-image: linear-gradient(90deg, rgba(255, 255, 255, 1) 47%, rgba(255, 255, 255, 0.5) 100%); */
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}

.contentCadastro>div>div {
    margin-left: 3rem;
    margin-right: 3rem;
}
.group {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.contentCadastro>div>div>form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 1rem;
    border: 2px solid #CED1D5;
    background-color: white;
}
.contentCadastro>div>div>form>h1{
    font-family: BalooThambi2;
    font-size: xx-large;
    font-weight: normal;
}

.b1, .b2, .b3 {
    width: 33%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.b1 {
    margin-left: 3rem;
}

.b3 {
    margin-right: 3rem;
}

.b1>input, .b2>input, .b3>div>input {
    width: 80%;
    height: 2rem;
    border-radius: 1rem;
    border: none;
    background-color: #666666;
    box-shadow: 5px 2px 2px lightgray;
    padding-left: 1rem;
    color: white;
    font-weight: bolder;
    font-family: BalooThambi2;
    margin-bottom: 0.5rem;
}
.b3>div {
    width: 100%;
    display: flex;
    justify-content: right;
}
.b3>div>input{
    width: 75%;
}
.b3>div>button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: large;
    border: none;
    background-color: transparent;
}

.contentCadastro>div>div>form>div>div>input::placeholder {
    color: white;
    font-family: BalooThambi2;
}

.group2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    width: 100%;
    font-family: BalooThambi2;
}
#campoObrig, #senhasDiff, #emailsDiff {
    display: none;
    color: red;
    text-align: center;
}

.contentCadastro>div>div>form>button {
    width: 20%;
    height: 2rem;
    border-radius: 1rem;
    border: none;
    background-color: #1DB31D;
    font-weight: lighter;
    color: white;
    font-family: BalooThambi2;
    font-size: large;
    margin-bottom: 1rem;
}
.contentCadastro>div>div:last-child>form>button {
    margin-top: 1rem;
}

.contentCadastro>div:last-child>img {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

#barraLvlSenha, #txtLvlSenha, #txtReqSenha {
    width: 90%;
    display: flex;
}
#txtLvlSenha {
    margin: 0;
    padding: 0;
    justify-content: right;
}
#txtLvlSenha>p {
    margin: 0;
    font-size: smaller;
    letter-spacing: 1px;
    font-weight: bold;
}
#barraLvlSenha, #txtReqSenha{
    flex-direction: column;
}

#forca-senha {
    height: 5px;
    transition: width 0.3s;
}

#falta-minimo, #falta-letra, #falta-especial {
    margin: 0;
    font-size: small;
    font-weight: bold;
}

/* celular */

@media screen and (max-width: 1080px) and (max-height: 2400px) {
    .TelaCadastro {
        background-image: none;
        background-color: #CED1D5;
    }

    .contentCadastro {
        background-image: none;
    }

    .contentCadastro>div {
        width: 95% !important;
        padding-left: 0;
    }

    .cadastro {
        width: 100% !important;
        margin-left: 0 !important;
    }

    .group {
        flex-direction: column;
    }

    .b1, .b2, .b3 {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .b1>input, .b2>input, .b3>input {
        width: 80%;
    }

    .contentCadastro>div:last-child>img {
        width: 80%;
    }

    .group2 {
        text-align: justify;
    }
    .group2>div {
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .group2>div:first-child {
        margin-bottom: 2rem;
    }

    .contentCadastro>div>div>form>button {
        width: 40%;
    }
}

@media screen and (max-width: 1366px) and (max-height: 768px) {
    .TelaCadastro{
        background-position-x: 0, 14%, 27.5%, 49.5%, 100%;
        background-size: 59%, 59%, 59%, 59%, 50%;
    }
    .contentCadastro {
        background-size: 80%;
        background-position-y: 6rem;
        padding-top: 6rem;
    }
    .contentCadastro>div {
        width: 80%;
    }
}