@font-face {
    font-family: BalooThambi2;
    src: url('../../Font/BalooThambi2-VariableFont_wght.ttf');
}

.TelaAD {
    width: 100%;
    background-image: url('../../img/Background/Postion\ Absolute\ -\ bg-01-1.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-01-2.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-01-3.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-01.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-04.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-04.png');
    background-repeat: no-repeat;
    background-position-y: -3rem, -3rem, -3rem, -3rem, 70%, 115%;
    background-position-x: 0, 14%, 27.5%, 41%, 50%, 115%;
    background-size: 59%, 59%, 59%, 59%, 80%, 45%;
}

.contAD {
    padding-top: 7rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url('../../img/Background/Postion\ Absolute\ -\ bg-01-2.png');
    background-position-y: 7rem;
    background-size: 80%;
    background-repeat: no-repeat;
}

.inicioAD {
    width: 60%;
    margin-top: 1rem;
    border-radius: 1rem;
    display: flex;
    padding: 2rem;
    background-image: linear-gradient(100deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.5) 100%);
}
.inicioAD>div:first-child {
    border: 2px solid #EECB36;
    border-radius: 1rem;
    padding: 2rem;
    margin-left: 2rem;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.inicioAD>div:first-child>form>h1 {
    font-family: BalooThambi2;
    letter-spacing: 2px;
}
.inicioAD>div>form>input,select {
    margin-bottom: 1rem;
    width: 100%;
    height: 2rem;
    border: none;
    border-radius: 1rem;
    background-color: #666666;
    color: white;
    padding-left: 1rem;
    font-weight: bold;
    font-family: BalooThambi2;
}
input::placeholder {
    color: white;
}
form>button {
    width: 50%;
    height: 2.5rem;
    border: none;
    border-radius: 1rem;
    background-color: #EECB36;
    color: white;
    font-weight: bold;
    font-size: medium;
    font-family: BalooThambi2;
}
form>button:hover {
    cursor: pointer;
}
form>h1 {
    margin-top: 0;
}

.inicioAD>div:last-child {
    display: flex;
    justify-content: right;
    align-items: center;
}
.inicioAD>div:last-child>img {
    width: 60%;
}

.histAD {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    padding: 2rem 1rem 2rem 1rem;
    border-radius: 1rem;
    width: 61.5%;
    background-color: rgba(255, 255, 255, 0.5);
}

.parceirosAD {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 63%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}


/* celular */
@media screen and (max-width: 1080px) and (max-height: 2400px) {
    .TelaAD {
        background-image: none;
        background-color: #F7E59B;
    }
    .contAD {
        background-image: none;
    }

    .inicioAD {
        width: 85% !important;
        flex-direction: column;
        padding: 1rem;
    }
    .inicioAD>div:first-child {
        margin: 0;
    }
    .inicioAD>div:first-child>form>h1 {
        width: 100%;
        font-size: x-large;
        text-align: center;
    }
    .inicioAD>div:last-child {
        margin-top: 1rem;
        justify-content: center;
    }

    .histAD {
        width: 85% !important;
    }

    .parceirosAD {
        width: 97% !important;
    }

}

@media screen and (max-width: 1366px) and (max-height: 768px) {
    .TelaAD {
        background-size: 59%, 59%, 59%, 59%, 90%, 50%;
        background-position-y: -3rem, -3rem, -3rem, -3rem, 50%, 105%;
        background-position-x: 0, 14%, 27.5%, 49.5%, 50%, 115%;
    }
    .contAD {
        padding-top: 6rem;
        background-position-y: 6rem;
    }
    .inicioAD {
        width: 75%;
    }
    .histAD {
        width: 77.5%;
    }
    .parceirosAD {
        width: 80%;
    }
}