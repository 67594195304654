@font-face {
    font-family: BalooThambi2;
    src: url('../../Font/BalooThambi2-VariableFont_wght.ttf');
}
@font-face {
    font-family: Gitaluevo;
    src: url('../../Font/Gitaluevo.ttf');
}

.menu {
    position: fixed;
    width: 100%;
    height: 7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    background-image: url('../../img/Background/Postion\ Absolute\ -\ bg-01-1.png')
      , url('../../img/Background/Postion\ Absolute\ -\ bg-01-2.png')
      , url('../../img/Background/Postion\ Absolute\ -\ bg-01-3.png')
      , url('../../img/Background/Postion\ Absolute\ -\ bg-01.png');
    background-repeat: no-repeat;
    background-position-y: -3rem, -3rem, -3rem, -3rem;
    background-position-x: 0, 14%, 27.5%, 41%;
    background-size: 59%, 59%, 59%, 59%;
    z-index: 1000;
    transition: background-color 0.5s ease;
}

.menu.scroll {
    background-color: rgba(255, 255, 255, 0.5);
}

.logo {
    display: flex;
    justify-content: end;
    padding-right: 2rem;
    flex: 1;
}
.logo>div {
    display: flex;
    align-items: center;
    margin-right: 10rem;
}
.logo>div>button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
}
.logo>div>button:hover {
    cursor: pointer;
}
.logo>div>button>img {
    width: 25%;
    height: 50%;
}
.logo>div>button>h1 {
    font-family: Gitaluevo;
    font-size: 300%;
    color: white;
}

.buttons {
    display: flex;
    flex: 1;
    align-items: center;
}
.buttons>button {
    width: 11%;
    border: none;
    background: none;
    text-align: right;
    font-family: BalooThambi2, Arial, Helvetica, sans-serif;
    color: white;

}
.buttons>button:last-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2rem;
    width: 9%;
    /* border: 1px solid; */
    border-radius: 1rem;
    margin-top: 0.5%;
    margin-left: 2rem;
    font-weight: bolder;
    text-align: center;
    /* font-size: x-large; */
    color: #666666;
}

.buttons>button:hover {
    cursor: pointer;
    font-weight: bold;
}

.buttons.show {
    display: none;
}

.menu-toggle {
    display: none;
    cursor: pointer;
}

.menu-toggle .hamburger {
    width: 25px;
    background-color: transparent;
    border: none;
    font-size: x-large;
    margin: 5px 0;
}

.fotoPerfilMenu {
    border-radius: 100%;
    width: 50%;
}

@media screen and (max-width: 1366px) and (max-height: 768px) {
    .menu{
        width: 100%;
        height: 6rem;
        background-size: 59%, 61%, 61%, 61%;
        background-position-x: 0, 13%, 30%, 47%;
    }
    .logo {
        flex: 0.5;
    }
    .menu>div:last-child {
        padding-left: 10rem;
    }
    .logo>div {
        margin-right: 0;
        margin-left: 1rem;
    }
    .buttons{
        flex: 1;
        width: 25%;
    }
    .buttons>button {
        width: 12%;
    }
    .buttons>button:first-child {
        margin-left: 10%;
    }

    .fotoPerfilMenu {
        margin-right: 0.5rem;
    }
}

@media screen and (max-width: 1080px) and (max-height: 2400px) {
    .menu {
        width: 100%;
        height: 5rem;
        justify-content: left;
        background-image: none;
        padding-left: 0;
        transition: color 0.5 ease;
    }
    .menu.scroll>div>div>button>h1 {
        color: #666666;
    }
    .logo {
        flex: 0;
        margin: 0;
    }
    .logo>div {
        margin: 0;
    }
    .logo>div>button>img {
        width: 40%;
    }
    .buttons {
        display: none;
    }

    .buttons>button:last-child {
        justify-content: left;
        font-size: small;
        font-weight: lighter;
        color: white;
    }
    
    /* .buttons.show {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: end;
        position: absolute;
        top: 5rem;
        background-color: #666666;
        padding: 1rem 0 0 0;
        width: 20%;
        height: 100svh;
    } */
    .buttons.show {
        display: flex;
        flex-direction: column;
        width: auto;
        height: 100svh;
        position: absolute;
        top: 5rem;
        background-color: #666666;
    }

    .buttons.show>button {
        text-align: left;
        width: 100%;
        margin: 0;
        padding: 0 0.5rem 0 0.5rem;
        margin-top: 0.5rem;
    }
    /* .buttons.show>button {
        margin-bottom: 0.5rem !important;
        text-align: left;
        width: 200%;
        margin: 0 100% 0 0;
    } */
    .buttons.show>button>img {
        width: 15%;
    }

    .menu-toggle {
        display: block;
        margin-right: 1rem;
        margin-left: 1rem;
    }

    .fotoPerfilMenu {
        width: 8% !important;
        margin-right: 0.5rem;
    }
}
