
.telaAltFotoPerfil>form>div, .telaAltFotoPerfil>form, .telaAltFotoPerfil>form>div>div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.telaAltFotoPerfil>form>div>div {
    background-color: #666666;
    padding: 1.5rem 0 1.5rem 0;
    border-radius: 1rem;
    margin-top: 1rem;
}
.telaAltFotoPerfil>form>div>div>img {
    border-radius: 100%;
    width: 70%;
}

.buttonAltFotoPerfil {
    margin-top: 1rem;
    width: 40%;
    height: 2rem;
    border-radius: 1rem;
    border: none;
    color: white;
    font-size: large;
    font-weight: bolder;
    font-family: BalooThambi2;
    background-color: #1DB31D;
    transition: background-color 0.5s ease;
}
.buttonAltFotoPerfil:hover {
    cursor: pointer;
    background-color: #116d11;
}
