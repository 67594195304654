.telaCadRealizado {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: BalooThambi2;
    font-weight: bold;
    text-align: center;
}
.telaCadRealizado>div>p:first-child {
    color: #116d11;
}
.telaCadRealizado>div>p:last-child {
    color: #666666;
}
.telaCadRealizado>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.telaCadRealizado>div>button {
    width: 25%;
    height: 2rem;
    border: none;
    border-radius: 1rem;
    background-color: #1DB31D;
    font-family: BalooThambi2;
    font-weight: bold;
    color: white;
    transition: background-color 0.5s ease;
}
.telaCadRealizado>div>button:hover {
    cursor: pointer;
    background-color: #116d11;
}