.carousel-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-viewport {
  width: 830px;
  overflow-x: hidden;
}

.carousel {
  display: flex;
  transition: all 0.5s ease;
}

.carousel-item {
  flex: 0 0 auto;
  width: 200px;
  height: 100px;
  background-color: lightgray;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
}
.carousel-item>img {
  width: 50%;
}

.carousel-button {
  padding: 5px 10px;
  font-size: 20px;
  border: none;
  cursor: pointer;
}

.carousel-button:hover {
  background-color: lightgray;
}

/* @media screen and (max-width: 1280px) and (max-height: 800px) {
  .carousel-viewport {
    width: 700px;
  }
  .carousel-item {
    width: 165px;
    height: 90px;
  }
} */

@media screen and (max-width: 1080px) and (max-height: 2400px) {
  .carousel-viewport {
    width: 80% !important;
  }
  .carousel-item {
    width: 35% !important;
    height: 70% !important;
  }
}

@media screen and (max-width: 1366px) and (max-height: 768px) {
  .carousel-viewport {
    width: 800px;
  }
  .carousel-item {
    width: 200px;
    height: 100px;
  }
}
