.telaExcDemanda {
    display: flex;
    justify-content: center;
    width: 100%;
}

.telaExcDemanda>button {
    border: none;
    border-radius: 1rem;
    width: 20%;
    height: 2rem;
    background-color: #E68B8B;
    color: white;
    font-weight: bolder;
    font-family: BalooThambi2;
    letter-spacing: 1px;
}
.telaExcDemanda>button:hover {
    cursor: pointer;
}

@media screen and (max-width: 1366px) and (max-height: 768px) {
    .telaExcDemanda>button {
        width: 35%;
    }
}

@media screen and (max-width: 1080px) and (max-height: 2400px) {
    .telaExcDemanda>button {
        width: 30%;
    }
}