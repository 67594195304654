.telaRecSenha {
    background-image: url('../../img/Background/Postion\ Absolute\ -\ bg-01-1.png')
      , url('../../img/Background/Postion\ Absolute\ -\ bg-01-2.png')
      , url('../../img/Background/Postion\ Absolute\ -\ bg-01-3.png')
      , url('../../img/Background/Postion\ Absolute\ -\ bg-01.png');
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-position-y: -3rem, -3rem, -3rem, -3rem;
    background-position-x: 0, 14%, 27.5%, 41%;
    background-size: 59%, 59%, 59%, 59%;
}

.contentRecSenha {
    padding-top: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.contentRecSenha>div {
    width: 40%;
}
.contentRecSenha>div>div:first-child {
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    border-radius: 1rem;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}
.contentRecSenha>div>div>div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    /* border: 1px solid; */
}
.contentRecSenha>div>div>div>p {
    font-weight: bold;
    font-size: large;
}
.contentRecSenha>div>div>div>input{
    width: 30%;
    height: 2rem;
    border-radius: 1rem;
    border: none;
    background-color: #666666;
    box-shadow: 5px 2px 2px lightgray;
    padding-left: 1rem;
    color: white;
    font-weight: bolder;
    font-family: BalooThambi2;
}
.contentRecSenha>div>div>div>button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: large;
    border: none;
    background-color: transparent;
}

.contentRecSenha>div>div:last-child {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}

.bttSalEsqSenha{
    width: 20%;
    height: 2rem;
    border-radius: 1rem;
    border: none;
    font-weight: lighter;
    color: white;
    font-family: BalooThambi2;
    font-size: large;
    background-color: #1DB31D !important;
    transition: background-color 0.5s ease;
}
.bttSalEsqSenha:hover {
    cursor: pointer;
    background-color: #116d11 !important;
}

@media screen and (max-width: 1366px) and (max-height: 768px) {
    .telaRecSenha {
        background-position-y: -2rem, -2rem, -2rem, -2rem;
        background-position-x: 0, 11.5%, 28.5%, 46%;
        background-size: 58.5%, 61%, 61%, 61%;
    }
    .contentRecSenha {
        padding-top: 6rem;
    }
    .contentRecSenha>div {
        width: 60%;
    }

    .bttSalEsqSenha {
        width: 15%;
    }
}

@media screen and (max-width: 1080px) and (max-height: 2400px) {
    .telaRecSenha {
        background-image: none;
        background-color: #8ED98E;
    }
    .contentRecSenha>div {
        width: 90%;
    }
    .contentRecSenha>div>div>div>input{
        width: 60%;
    }

    .contentRecSenha>div>div:last-child>img {
        width: 90%;
    }
}