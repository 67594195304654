.telaDetDemanda {
    border: 2px solid #CED1D5;
    border-radius: 1rem;
    padding-bottom: 2rem;
}
.telaDetDemanda>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.telaDetDemanda>div>div {
    display: flex;
    width: 100%;
    padding: 1rem;
}
.telaDetDemanda>div>div>div:first-child {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 1.5rem;
}
.telaDetDemanda>div>div>div:first-child>div:first-child{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin-bottom: 2rem;
}
.telaDetDemanda>div>div>div:first-child>div:first-child>h3 {
    width: 40%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
    border-radius: 1rem;
    color: white;
    font-family: BalooThambi2;
}

.telaDetDemanda>div>div>div:first-child>div:first-child>input, .telaDetDemanda>div>div>div:first-child>input {
    height: 2rem;
    border-radius: 1rem;
    border: none;
    background-color: #666666;
    padding-left: 1rem;
    color: white;
    font-weight: bolder;
    font-family: BalooThambi2;
    letter-spacing: 1px;
}
.telaDetDemanda>div>div>div:first-child>div:first-child>input{
    width: 40%;
}
.telaDetDemanda>div>div>div:first-child>input {
    margin-bottom: 0.5rem;
}
.telaDetDemanda>div>div>div:first-child>textarea {
    background-color: #666666;
    border: none;
    border-radius: 1rem;
    color: white;
    font-family: BalooThambi2;
    font-weight: bolder;
    letter-spacing: 1px;
    padding-left: 1rem;
    min-height: 5rem;
}

.telaDetDemanda>div>div>div:last-child {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
}
.telaDetDemanda>div>div>div:last-child>div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #666666;
    width: 50%;
    height: 100%;
    border-radius: 1rem;
}
.telaDetDemanda>div>div>div:last-child>div>img {
    width: 70%;
}

.telaDetDemanda>div>div:last-child {
    justify-content: center;
}
.telaDetDemanda>div>div:last-child>button {
    border: none;
    border-radius: 1rem;
    width: 10%;
    height: 2rem;
    background-color: #E68B8B;
    color: white;
    font-weight: bolder;
    font-family: BalooThambi2;
    letter-spacing: 1px;
}
.telaDetDemanda>div>div:last-child>button:hover {
    cursor: pointer;
}

@media screen and (max-width: 1366px) and (max-height: 768px) {
    .telaDetDemanda>div>div>div:first-child>div:first-child {
        width: 100%;
    }
    .telaDetDemanda>div>div>div:last-child>div {
        width: 60%;
    }
}

@media screen and (max-width: 1080px) and (max-height: 2400px) {
    .telaDetDemanda>div>div {
        padding-top: 0;
        margin-top: 0;
        flex-direction: column;
    }
    .telaDetDemanda>div>div>div:first-child>div:first-child {
        flex-direction: column;
    }
    .telaDetDemanda>div>div>div:first-child>div:first-child>h3 {
        width: 60%;
    }

    .telaDetDemanda>div>div>div:last-child>div {
        width: 70%;
        min-height: 20rem;
    }

    .telaDetDemanda>div>div:last-child {
        align-items: center;
    }
    .telaDetDemanda>div>div:last-child>button {
        width: 25%;
    }
}