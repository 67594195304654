@font-face {
  font-family: BalooThambi2;
  src: url('../../Font/BalooThambi2-VariableFont_wght.ttf');
}

.TelaGaleria>div>div>img {
  border-radius: 1rem;
}
.TelaGaleria>div>div {
  padding: 10px;
  border-radius: 1rem;
}
.TelaGaleria>div>div:hover {
  background-color: white;
  cursor: pointer;
}
.TelaGaleria>div>div:last-child:hover {
  background-color: transparent;
  cursor: default;
}

.TelaGaleria>div:last-child>button {
  width: 20%;
  height: 2.5rem;
  border: none;
  border-radius: 1rem;
  color: white;
  font-weight: bold;
  font-size: large;
  font-family: BalooThambi2;
  letter-spacing: 2px;
}
.TelaGaleria>div:last-child>button:hover {
  cursor: pointer;
}
.TelaGaleria>div>div>p {
  font-size: 20px;
  margin-top: 0;
  text-align: center;
  font-family: BalooThambi2;
}

.galeria-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .galeria-imagem {
    width: calc(25% - 20px);
    max-width: 200px;
  }
  
  .load-more-container {
    text-align: center;
    margin: 20px;
  }
  
  @media screen and (max-width: 1080px) and (max-height: 2400px) {
    .galeria-imagem {
      width: calc(50% - 20px);
    }

    .TelaGaleria>div:last-child>button {
      width: 30%;
    }

    .TelaGaleria>div>div>p {
      font-size: 12px;
    }
  }
  