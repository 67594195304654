@font-face {
  font-family: BalooThambi2;
  src: url('../../Font/BalooThambi2-VariableFont_wght.ttf');
}
  
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: #666666;
    font-size: 16px;
    line-height: 1.5;
    overflow-x: hidden;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    color: #666666;
    font-size: 16px;
    line-height: 1.5;
    font-family: BalooThambi2;
    background-image: url('../../img/Background/Postion\ Absolute\ -\ bg-01-1.png')
      , url('../../img/Background/Postion\ Absolute\ -\ bg-01-2.png')
      , url('../../img/Background/Postion\ Absolute\ -\ bg-01-3.png')
      , url('../../img/Background/Postion\ Absolute\ -\ bg-01.png')
      , url('../../img/Background/Postion\ Absolute\ -\ bg-04.png')
      , url('../../img/Background/Postion\ Absolute\ -\ bg-footer-3.png')
      , url('../../img/Background/Postion\ Absolute\ -\ bg-04-1.png')
      , url('../../img/Background/Postion\ Absolute\ -\ bg-04-2.png')
      , linear-gradient(100deg, #BDC5EA 15%, #F7E59B 100%);
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-position-y: -3rem, -3rem, -3rem, -3rem, 35%, 65%, 90%, 110%, 100%;
    background-position-x: 0, 14%, 27.5%, 41%, 100%, -8rem, 100%, -8rem, 100%;
    background-size: 59%, 59%, 59%, 59%, 50%, 50%, 50%, 50%, 100%;
  }
  
  .App-header {
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    text-align: center;
  }

  .TelaHome {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .inicio {
    display: flex;
    width: 60%;
    padding: 3rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
    border-radius: 1rem;
    background-image: linear-gradient(200deg, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 100%);
  }
  .inicio>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    text-align: justify;
    padding-bottom: 3rem;
    margin-left: 3rem;
  }
  .inicio>div:last-child {
    margin-left: 0;
  }
  .inicio>div>button {
    width: 30%;
    height: 15%;
    border-radius: 1rem;
    border: none;
    background-color: #8ED98E;
    font-weight: bolder;
    font-family: BalooThambi2;
    color: white;
  }
  .inicio>div>img {
    margin-top: 3rem;
    width: 100%;
    height: 100%;
  }
  
  .meio {
    margin-top: 7rem;
    display: flex;
    width: 60%;
    padding: 3rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
    border-radius: 1rem;
    background-image: linear-gradient(100deg, rgba(255, 255, 255, 1) 55%, rgba(255, 255, 255, 0.5) 100%);
  }
  .meio>div {
    padding: 1rem;
  }
  .meio>div>div>h1 {
    text-align: center;
  }
  .meio>div>div>p {
    text-align: justify;
  }
  .meio>div>div>img {
    margin-top: 8rem;
    width: 100%;
  }
  
  .grid-cards {
    display: grid;
    grid-template-areas: 'services-section demands-section'
    'intelligent-neighborhood-section report-problems-section';
    gap: 2rem;
  }
  .grid-cards>div {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    border-radius: 1rem;
    color: white;
    align-items: center;
  }
  .grid-cards>div>h2 {
    font-size: x-large;
    text-align: center;
  }
  .grid-cards>div>p {
    text-align: justify;
  }
  .grid-cards>div>img {
    width: 30%;
  }
  
  .services-section{
    grid-area: services-section;
    background-color: #1DB31D;
  }
  .demands-section {
    grid-area: demands-section;
    background-color: #EECB36;
    ;
  }
  .intelligent-neighborhood-section {
    grid-area: intelligent-neighborhood-section;
    background-color: #3C59E1;
  }
  .report-problems-section {
    grid-area: report-problems-section;
    background-color: #CD1616;
  }
  
  .meio>div>div:last-child {
    margin-top: 3rem;
  }
  .meio>div>div:last-child>button {
    width: 50%;
    height: 3rem;
    border-radius: 1rem;
    border: none;
    font-family: BalooThambi2;
    font-size: x-large;
    color: white;
    background-image: linear-gradient(rgba(142, 217, 142, 1) 0%, rgba(60, 89, 225, 1) 100%);
  }
  
  .final {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
  }
  
  .final>div {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }
  .final>div:last-child {
    margin-bottom: 0;
  }
  
  .final>div>button {
    width: 40%;
    height: 3rem;
    border-radius: 2rem;
    background-color: #EECB36;
    border: none;
    box-shadow: 5px 5px 10px #cfcfcf;
    font-size: x-large;
    color: white;
    font-weight: bolder;
    font-family: BalooThambi2, sans-serif;
  }
  .video {
    width: 560px;
    height: 315px;
    border-radius: 1rem;
  }
  .final>div>video {
    width: 50%;
    border-radius: 1rem;
  }
  
  .contParceiros {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 61.5%;
    margin-top: 1rem;
  }
  
  footer {
    width: 63.5%;
    text-align: center;
    padding: 1rem;
    border-radius: 1rem;
    background-image: linear-gradient( rgba(255, 255, 255, 0) 5%, rgba(255, 255, 255, 1) 60%);
  }

@media screen and (max-width: 1080px) and (max-height: 2400px) {
  .App {
    width: 100%;
    background-image: none;
    background-color: #8ED98E;
  }

  .TelaHome {
    width: 100%;
  }

  .inicio{
    width: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .inicio>div {
    margin: 0;
  }
  .inicio>div>button {
    width: 50%;
    height: 2rem;
  }

  .meio {
    width: 70%;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .meio>div {
    padding: 0;
  }
  .meio>div>div>img {
    margin-top: 0;
    margin-bottom: 2rem;
  }
  .grid-cards {
    display: flex;
    flex-direction: column;
  }
  .meio>div>div:last-child>button {
    width: 100% !important;
  }

  .final>div>video {
    width: 150%;
    border-radius: 1rem;
  }

  .final>div>button {
    width: 100% !important;
    height: 4.5rem;
  }

  .contParceiros {
    /* margin-left: 1rem; */
    width: 100% !important;
  }

}
  
@media screen and (max-width: 1366px) and (max-height: 768px) {
  .App{
    background-position-y: -2rem, -2rem, -2rem, -2rem, 30%, 55%, 80%, 110%, 100%;
    background-position-x: 0, 11.5%, 28.5%, 46%, 100%, -8rem, 100%, -8rem, 100%;
    background-size: 58.5%, 61%, 61%, 61%, 50%, 50%, 50%, 50%, 100%;
  }
  .inicio {
    width: 70%;
    margin-top: 6rem;
  }
  .meio {
    width: 70%;
  }
  .meio>div>div:last-child>button {
    width: 60%;
  }
  .final {
    width: 70%;
  }

  .contParceiros {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
  }

  .sugestao>form {
    width: 90%;
  }
}
