.telaSenhaAlt {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.telaSenhaAlt>div {
    width: 100%;
    display: flex;
    justify-content: center;
}
.telaSenhaAlt>div>p {
    color: #116d11;
    text-align: center;
}
.telaSenhaAlt>div>button {
    width: 20%;
    height: 2rem;
    border-radius: 1rem;
    border: none;
    font-weight: lighter;
    color: white;
    font-family: BalooThambi2;
    font-size: large;
    background-color: #1DB31D !important;
    transition: background-color 0.5s ease;
}
.telaSenhaAlt>div>button {
    cursor: pointer;
    background-color: #116d11 !important;
}