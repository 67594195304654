.telaListEventos {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.telaListEventos>div {
    width: 60%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 1rem;
    border-radius: 1rem;
}
.telaListEventos>div * {
    flex: 1;
    text-align: center;
}
.telaListEventos>div>button {
    background-color: transparent;
    border: none;
}
.telaListEventos>div>button:hover {
    cursor: pointer;
}

.autorizado {
    background-color: #8ED98E;
}
.em_analise {
    background-color: #F7E59B;
}
.nao_analisado {
    background-color: #9EACF0;
}
.recusado {
    background-color: #E68B8B;
}

@media screen and (max-width: 1366px) and (max-height: 768px) {
    .telaListEventos>div {
        width: 80%;
    }
}