.telaUsrNaoLogado {
    width: 100%;
    display: flex;
    justify-content: center;
}
.telaUsrNaoLogado>div {
    width: 50%;
    display: flex;
    justify-content: space-between;
}

.buttonIrLogin, .buttonIrCadastro {
    width: 45%;
    height: 2rem;
    border-radius: 1rem;
    border: none;
    font-weight: lighter;
    color: white;
    font-family: BalooThambi2;
    font-size: large;
    /* margin-bottom: 1rem; */
}
.buttonIrLogin {
    background-color: #116d11;
}
.buttonIrCadastro {
    background-color: #1DB31D;
}
.buttonIrLogin:hover, .buttonIrCadastro:hover {
    cursor: pointer;
}

@media screen and (max-width: 1080px) and (max-height: 2400px) {
    .telaUsrNaoLogado>div {
        width: 70%;
    }
}