@font-face {
    font-family: BalooThambi2;
    src: url('../../Font/BalooThambi2-VariableFont_wght.ttf');
}

.TelaAS {
    width: 100%;
    /* background-color: #8ED98E; */
    background-image: url('../../img/Background/Postion\ Absolute\ -\ bg-01-1.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-01-2.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-01-3.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-01.png')
    , url('../../img/Background/Postion\ Absolute\ -\ bg-04-2.png');
    background-repeat: no-repeat;
    background-position-y: -3rem, -3rem, -3rem, -3rem, 120%;
    background-position-x: 0, 14%, 27.5%, 41%, 120%;
    background-size: 59%, 59%, 59%, 59%, 50%;
}

.contAS {
    padding-top: 7rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-image: url('../../img/Background/Postion\ Absolute\ -\ bg-01-1.png');
    background-position-y: 7rem;
    background-size: 80%;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
}

.inicioAS {
    width: 59%;
    margin-top: 1rem;
    border-radius: 1rem;
    display: flex;
    padding: 2rem;
    background-image: linear-gradient(100deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.5) 100%);
}
.inicioAS>div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-left: 2rem;
    padding: 1rem;
    padding-top: 0;
    border: 2px solid #8ED98E;
    border-radius: 1rem;
}
.inicioAS>div:first-child>form {
    display: flex;
    flex-direction: column;
}
.inicioAS>div:first-child>form>h1 {
    font-family: BalooThambi2;
    letter-spacing: 2px;
}
.inicioAS>div:first-child>form>div {
    display: flex;
    justify-content: space-between;
}
.inicioAS>div:first-child>form>input, .inicioAS>div:first-child>form>select, .inicioAS>div:first-child>form>div>button, .inicioAS>div:first-child>form>div>input {
    height: 2rem;
    border-radius: 1rem;
    border: none;
    background-color: #00000040;
    padding-left: 1rem;
    padding-right: 1rem;
    color: white;
    font-weight: bold;
    font-family: BalooThambi2;
}
.inicioAS>div:first-child>form>div>button {
    padding: 0;
    background-color: #1DB31D;
    width: 50%;
    height: 2.5rem;
}
.inicioAS>div:first-child>form>input {
    width: 90%;
    /* margin-top: 1rem; */
    margin-bottom: 1rem;
}
.inicioAS>div:first-child>div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.inicioAS>div:first-child>div>button {
    width: 50%;
    height: 2rem;
    border: none;
    border-radius: 1rem;
    font-weight: bold;
    background-color: #1DB31D;
    color: white;
    font-family: BalooThambi2;
}

.ASparceiros {
    width: 62%;
}

@media screen and (max-width: 1080px) and (max-height: 2400px) {
    .TelaAS {
        background-image: none;
        background-color: #8ED98E;
    }

    .contAS {
        background-image: none;
    }

    .inicioAS {
        width: 86% !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem;
    }
    .inicioAS>div:first-child {
        margin-left: 0 !important;
    }
    .inicioAS>div:first-child>form>h1 {
        width: 100%;
        font-size: x-large;
        text-align: center;
        margin: 2rem 0 1rem 0;
    }
    .inicioAS>div:first-child>form>input {
        width: 90%;
    }
    .inicioAS>div:first-child>div>button {
        margin-top: 2rem;
    }
    .inicioAS>div:last-child>img {
        margin-top: 1rem;
        width: 100%;
    }

    .ASparceiros {
        width: 97%;
        display: flex;
        justify-content: center;
    }
}
 
@media screen and (max-width: 1366px) and (max-height: 768px) {
    .TelaAS {
        background-position-y: 0, 0, 0, -2.5rem, 120%;
        background-position-x: 0, 14%, 27.5%, 49%, 120%;
    }

    .contAS {
        background-position-y: 6rem;
        padding-top: 6rem;
    }

    .inicioAS {
        width: 75%;
    }
    .inicioAS>div:first-child {
        margin-left: 1rem;
    }
    .inicioAS>div:first-child>div>button {
        margin-top: 2rem;
    }
    .inicioAS>div:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .inicioAS>div:last-child>img {
        width: 100%;
    }

    .ASparceiros {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
    }
}